import {LoginURL, clientId, clientSecret, apiDomain, getHeader} from '@/assets/js/config.js'
import axios from 'axios'

export default {
  computed: {

  },
  data() {
    return {
      setup_module:{
        contractor_listing: null,
        developer_listing: null,
        project_category_listing: null,
        project_type_listing: null,
        safety_observation_category_listing: null,
        user_listing: null,
      },
      project_module:{
        project_listing: null,
        project_milestone: null,
        project_group_listing: null,
        project_site_listing: null,
      },
      template_module:{
        document_template_listing: null,
        document_template_detail_Listing: null,
        milestone_template_listing: null,
        milestone_template_detail_listing: null,
        project_document_template_listing: null,
        project_document_template_detail_Listing: null,
        project_task_template_listing: null,
        project_task_template_detail_listing: null,
        task_template_listing: null,
        task_template_detail: null,
      },
      site_transaction_module:{
        site_document_listing: null,
        site_task_listing: null,
      },
      health_safety_module:{
        safety_observation_listing: null,
        safety_observation_report_listing: null,
        toolbox_meeting_listing: null,
      },
    }
  },
  methods: {
    output_menu_access()
    {

    },

    getLoginUserRoleAccessMenu()
    {
      axios.get(apiDomain + 'user_access/role_access.for_each_module', { headers: getHeader() })
      .then ( response => {
        if (response.status === 200)
        {
          // this.setup_module.contractor_listing = response.data.data.setup_modules[0].access_listing
          // this.setup_module.developer_listing = response.data.data.setup_modules[1].access_listing
          // this.setup_module.project_category_listing = response.data.data.setup_modules[2].access_listing
          // this.setup_module.project_type_listing = response.data.data.setup_modules[3].access_listing
          // this.setup_module.user_listing = response.data.data.setup_modules[4].access_listing
          // this.setup_module.module_brand_listing = response.data.data.setup_modules[5].access_listing

          this.setup_module.contractor_listing = response.data.data.setup_modules[0].access_listing
          this.setup_module.developer_listing = response.data.data.setup_modules[1].access_listing
          this.setup_module.module_brand_listing = response.data.data.setup_modules[2].access_listing
          this.setup_module.project_category_listing = response.data.data.setup_modules[3].access_listing
          this.setup_module.project_engineer_group_listing = response.data.data.setup_modules[4].access_listing
          this.setup_module.project_type_listing = response.data.data.setup_modules[5].access_listing
          this.setup_module.safety_observation_category_listing = response.data.data.setup_modules[6].access_listing
          this.setup_module.user_listing = response.data.data.setup_modules[7].access_listing
          

          this.project_module.project_listing = response.data.data.project_modules[0].access_listing
          this.project_module.project_group_listing = response.data.data.project_modules[1].access_listing
          this.project_module.project_milestone = response.data.data.project_modules[2].access_listing
          this.project_module.project_site_listing = response.data.data.project_modules[3].access_listing

          this.template_module.document_template_listing = response.data.data.template_modules[0].access_listing
          this.template_module.document_template_detail_Listing = response.data.data.template_modules[1].access_listing

          this.template_module.milestone_template_listing = response.data.data.template_modules[2].access_listing
          this.template_module.milestone_template_detail_listing = response.data.data.template_modules[3].access_listing

          this.template_module.project_document_template_listing = response.data.data.template_modules[4].access_listing
          this.template_module.project_document_template_detail_Listing = response.data.data.template_modules[5].access_listing

          this.template_module.project_task_template_listing = response.data.data.template_modules[6].access_listing
          this.template_module.project_task_template_detail_listing = response.data.data.template_modules[7].access_listing

          this.template_module.task_template_listing = response.data.data.template_modules[8].access_listing
          this.template_module.task_template_detail = response.data.data.template_modules[9].access_listing

          this.site_transaction_module.site_document_listing = response.data.data.site_transaction_modules[0].access_listing
          this.site_transaction_module.site_task_listing = response.data.data.site_transaction_modules[4].access_listing

          this.health_safety_module.safety_observation_listing = response.data.data.health_safety_modules[0].access_listing
          this.health_safety_module.safety_observation_report_listing = response.data.data.health_safety_modules[1].access_listing
          this.health_safety_module.toolbox_meeting_listing = response.data.data.health_safety_modules[2].access_listing
        }
      })
      .catch ( error => {
        console.log(error.response.status)
        this.errorFunction(error, "User Role Access Information")
      } );
    },

    getLoginUserRoleAccess()
    {
      axios.get(apiDomain + 'user_access/role_access.for_each_module', { headers: getHeader() })
      .then ( response => {
        if (response.status === 200)
        {
          this.setup_modules_set(response.data.data.setup_modules)
          this.project_modules_set(response.data.data.project_modules)
          this.template_modules_set(response.data.data.template_modules)
          this.site_transaction_modules_set(response.data.data.site_transaction_modules)
          this.project_transaction_modules_set(response.data.data.project_transaction_modules)
          this.health_safety_modules_set(response.data.data.health_safety_modules)
        }
      })
      .catch ( error => {
        console.log(error.response.status)
        this.errorFunction(error, "User Role Access Information")
      } );
    },

    project_transaction_modules_set(project_transaction_modules)
    {
      this.project_document_set(project_transaction_modules[0])
      this.project_document_approval_set(project_transaction_modules[1])
      this.project_document_recurring_set(project_transaction_modules[2])
      this.project_document_upload_set(project_transaction_modules[3])
      this.project_task_set(project_transaction_modules[4])
    },

    project_document_set(data)
    {
      const project_document_access = {}

      project_document_access.module_code = data.module_code
      project_document_access.access_listing = data.access_listing
      project_document_access.access_create = data.access_create
      project_document_access.access_show = data.access_show
      project_document_access.access_edit = data.access_edit
      project_document_access.access_delete = data.access_delete
      window.localStorage.setItem('project_document_access', JSON.stringify(project_document_access))
    },

    project_document_approval_set(data)
    {
      const project_document_approval_access = {}

      project_document_approval_access.module_code = data.module_code
      project_document_approval_access.access_listing = data.access_listing
      project_document_approval_access.access_create = data.access_create
      project_document_approval_access.access_show = data.access_show
      project_document_approval_access.access_edit = data.access_edit
      project_document_approval_access.access_delete = data.access_delete
      window.localStorage.setItem('project_document_approval_access', JSON.stringify(project_document_approval_access))
    },

    project_document_recurring_set(data)
    {
      const project_document_recurring_access = {}

      project_document_recurring_access.module_code = data.module_code
      project_document_recurring_access.access_listing = data.access_listing
      project_document_recurring_access.access_create = data.access_create
      project_document_recurring_access.access_show = data.access_show
      project_document_recurring_access.access_edit = data.access_edit
      project_document_recurring_access.access_delete = data.access_delete
      window.localStorage.setItem('project_document_recurring_access', JSON.stringify(project_document_recurring_access))
    },

    project_document_upload_set(data)
    {
      const project_document_upload_access = {}

      project_document_upload_access.module_code = data.module_code
      project_document_upload_access.access_listing = data.access_listing
      project_document_upload_access.access_create = data.access_create
      project_document_upload_access.access_show = data.access_show
      project_document_upload_access.access_edit = data.access_edit
      project_document_upload_access.access_delete = data.access_delete
      window.localStorage.setItem('project_document_upload_access', JSON.stringify(project_document_upload_access))
    },

    project_task_set(data)
    {
      const project_task_access = {}

      project_task_access.module_code = data.module_code
      project_task_access.access_listing = data.access_listing
      project_task_access.access_create = data.access_create
      project_task_access.access_show = data.access_show
      project_task_access.access_edit = data.access_edit
      project_task_access.access_delete = data.access_delete
      window.localStorage.setItem('project_task_access', JSON.stringify(project_task_access))
    },


    site_transaction_modules_set(site_transaction_modules)
    {
      this.site_document_set(site_transaction_modules[0])
      this.site_document_approval_set(site_transaction_modules[1])
      this.site_document_recurring_set(site_transaction_modules[2])
      this.site_document_upload_set(site_transaction_modules[3])
      this.site_task_set(site_transaction_modules[4])
    },

    site_document_set(data)
    {
      const site_document_access = {}

      site_document_access.module_code = data.module_code
      site_document_access.access_listing = data.access_listing
      site_document_access.access_create = data.access_create
      site_document_access.access_show = data.access_show
      site_document_access.access_edit = data.access_edit
      site_document_access.access_delete = data.access_delete
      window.localStorage.setItem('site_document_access', JSON.stringify(site_document_access))
    },

    site_document_approval_set(data)
    {
      const site_document_approval_access = {}

      site_document_approval_access.module_code = data.module_code
      site_document_approval_access.access_listing = data.access_listing
      site_document_approval_access.access_create = data.access_create
      site_document_approval_access.access_show = data.access_show
      site_document_approval_access.access_edit = data.access_edit
      site_document_approval_access.access_delete = data.access_delete
      window.localStorage.setItem('site_document_approval_access', JSON.stringify(site_document_approval_access))
    },

    site_document_recurring_set(data)
    {
      const site_document_recurring_access = {}

      site_document_recurring_access.module_code = data.module_code
      site_document_recurring_access.access_listing = data.access_listing
      site_document_recurring_access.access_create = data.access_create
      site_document_recurring_access.access_show = data.access_show
      site_document_recurring_access.access_edit = data.access_edit
      site_document_recurring_access.access_delete = data.access_delete
      window.localStorage.setItem('site_document_recurring_access', JSON.stringify(site_document_recurring_access))
    },

    site_document_upload_set(data)
    {
      const site_document_upload_access = {}

      site_document_upload_access.module_code = data.module_code
      site_document_upload_access.access_listing = data.access_listing
      site_document_upload_access.access_create = data.access_create
      site_document_upload_access.access_show = data.access_show
      site_document_upload_access.access_edit = data.access_edit
      site_document_upload_access.access_delete = data.access_delete
      window.localStorage.setItem('site_document_upload_access', JSON.stringify(site_document_upload_access))
    },

    site_task_set(data)
    {
      const site_task_access = {}

      site_task_access.module_code = data.module_code
      site_task_access.access_listing = data.access_listing
      site_task_access.access_create = data.access_create
      site_task_access.access_show = data.access_show
      site_task_access.access_edit = data.access_edit
      site_task_access.access_delete = data.access_delete
      window.localStorage.setItem('site_task_access', JSON.stringify(site_task_access))
    },

    template_modules_set(template_modules)
    {
      this.site_document_template_set(template_modules[0])
      this.site_document_template_detail_set(template_modules[1])
      this.milestone_template_set(template_modules[2])
      this.milestone_template_detail_set(template_modules[3])
      this.project_document_template_set(template_modules[4])
      this.project_document_template_detail_set(template_modules[5])
      this.project_task_template_set(template_modules[6])
      this.project_task_template_detail_set(template_modules[7])
      this.site_task_template_set(template_modules[8])
      this.site_task_template_detail_set(template_modules[9])
    },

    site_task_template_set(data)
    {
      const site_task_template_access = {}

      site_task_template_access.module_code = data.module_code
      site_task_template_access.access_listing = data.access_listing
      site_task_template_access.access_create = data.access_create
      site_task_template_access.access_show = data.access_show
      site_task_template_access.access_edit = data.access_edit
      site_task_template_access.access_delete = data.access_delete
      window.localStorage.setItem('site_task_template_access', JSON.stringify(site_task_template_access))
    },

    site_task_template_detail_set(data)
    {
      const site_task_template_detail_access = {}

      site_task_template_detail_access.module_code = data.module_code
      site_task_template_detail_access.access_listing = data.access_listing
      site_task_template_detail_access.access_create = data.access_create
      site_task_template_detail_access.access_show = data.access_show
      site_task_template_detail_access.access_edit = data.access_edit
      site_task_template_detail_access.access_delete = data.access_delete
      window.localStorage.setItem('site_task_template_detail_access', JSON.stringify(site_task_template_detail_access))
    },

    site_document_template_set(data)
    {
      const site_document_template_access = {}

      site_document_template_access.module_code = data.module_code
      site_document_template_access.access_listing = data.access_listing
      site_document_template_access.access_create = data.access_create
      site_document_template_access.access_show = data.access_show
      site_document_template_access.access_edit = data.access_edit
      site_document_template_access.access_delete = data.access_delete
      window.localStorage.setItem('site_document_template_access', JSON.stringify(site_document_template_access))
    },

    site_document_template_detail_set(data)
    {
      const site_document_template_detail_access = {}

      site_document_template_detail_access.module_code = data.module_code
      site_document_template_detail_access.access_listing = data.access_listing
      site_document_template_detail_access.access_create = data.access_create
      site_document_template_detail_access.access_show = data.access_show
      site_document_template_detail_access.access_edit = data.access_edit
      site_document_template_detail_access.access_delete = data.access_delete
      window.localStorage.setItem('site_document_template_detail_access', JSON.stringify(site_document_template_detail_access))
    },

    milestone_template_set(data)
    {
      const milestone_template_access = {}

      milestone_template_access.module_code = data.module_code
      milestone_template_access.access_listing = data.access_listing
      milestone_template_access.access_create = data.access_create
      milestone_template_access.access_show = data.access_show
      milestone_template_access.access_edit = data.access_edit
      milestone_template_access.access_delete = data.access_delete
      window.localStorage.setItem('milestone_template_access', JSON.stringify(milestone_template_access))
    },

    milestone_template_detail_set(data)
    {
      const milestone_template_detail_access = {}

      milestone_template_detail_access.module_code = data.module_code
      milestone_template_detail_access.access_listing = data.access_listing
      milestone_template_detail_access.access_create = data.access_create
      milestone_template_detail_access.access_show = data.access_show
      milestone_template_detail_access.access_edit = data.access_edit
      milestone_template_detail_access.access_delete = data.access_delete
      window.localStorage.setItem('milestone_template_detail_access', JSON.stringify(milestone_template_detail_access))
    },

    project_document_template_set(data)
    {
      const project_document_template_access = {}

      project_document_template_access.module_code = data.module_code
      project_document_template_access.access_listing = data.access_listing
      project_document_template_access.access_create = data.access_create
      project_document_template_access.access_show = data.access_show
      project_document_template_access.access_edit = data.access_edit
      project_document_template_access.access_delete = data.access_delete
      window.localStorage.setItem('project_document_template_access', JSON.stringify(project_document_template_access))
    },

    project_document_template_detail_set(data)
    {
      const project_document_template_detail_access = {}

      project_document_template_detail_access.module_code = data.module_code
      project_document_template_detail_access.access_listing = data.access_listing
      project_document_template_detail_access.access_create = data.access_create
      project_document_template_detail_access.access_show = data.access_show
      project_document_template_detail_access.access_edit = data.access_edit
      project_document_template_detail_access.access_delete = data.access_delete
      window.localStorage.setItem('project_document_template_detail_access', JSON.stringify(project_document_template_detail_access))
    },

    project_task_template_set(data)
    {
      const project_task_template_access = {}

      project_task_template_access.module_code = data.module_code
      project_task_template_access.access_listing = data.access_listing
      project_task_template_access.access_create = data.access_create
      project_task_template_access.access_show = data.access_show
      project_task_template_access.access_edit = data.access_edit
      project_task_template_access.access_delete = data.access_delete
      window.localStorage.setItem('project_task_template_access', JSON.stringify(project_task_template_access))
    },

    project_task_template_detail_set(data)
    {
      const project_task_template_detail_access = {}

      project_task_template_detail_access.module_code = data.module_code
      project_task_template_detail_access.access_listing = data.access_listing
      project_task_template_detail_access.access_create = data.access_create
      project_task_template_detail_access.access_show = data.access_show
      project_task_template_detail_access.access_edit = data.access_edit
      project_task_template_detail_access.access_delete = data.access_delete
      window.localStorage.setItem('project_task_template_detail_access', JSON.stringify(project_task_template_detail_access))
    },


    project_modules_set(project_modules)
    {
      const project_access = {}

      project_access.module_code = project_modules[0].module_code
      project_access.access_listing = project_modules[0].access_listing
      project_access.access_create = project_modules[0].access_create
      project_access.access_show = project_modules[0].access_show
      project_access.access_edit = project_modules[0].access_edit
      project_access.access_delete = project_modules[0].access_delete
      window.localStorage.setItem('project_access', JSON.stringify(project_access))

      this.project_group_set(project_modules[1])
      this.project_milestone_set(project_modules[2])
      this.project_site_set(project_modules[3])
      this.site_contractor_set(project_modules[4])
    },

    project_group_set(data)
    {
      const project_group_access = {}

      project_group_access.module_code = data.module_code
      project_group_access.access_listing = data.access_listing
      project_group_access.access_create = data.access_create
      project_group_access.access_show = data.access_show
      project_group_access.access_edit = data.access_edit
      project_group_access.access_delete = data.access_delete
      window.localStorage.setItem('project_group_access', JSON.stringify(project_group_access))
    },

    project_milestone_set(data)
    {
      const project_milestone_access = {}

      project_milestone_access.module_code = data.module_code
      project_milestone_access.access_listing = data.access_listing
      project_milestone_access.access_create = data.access_create
      project_milestone_access.access_show = data.access_show
      project_milestone_access.access_edit = data.access_edit
      project_milestone_access.access_delete = data.access_delete
      window.localStorage.setItem('project_milestone_access', JSON.stringify(project_milestone_access))
    },

    project_site_set(data)
    {
      const project_site_access = {}

      project_site_access.module_code = data.module_code
      project_site_access.access_listing = data.access_listing
      project_site_access.access_create = data.access_create
      project_site_access.access_show = data.access_show
      project_site_access.access_edit = data.access_edit
      project_site_access.access_delete = data.access_delete
      window.localStorage.setItem('project_site_access', JSON.stringify(project_site_access))
    },

    site_contractor_set(data)
    {
      const site_contractor_access = {}

      site_contractor_access.module_code = data.module_code
      site_contractor_access.access_listing = data.access_listing
      site_contractor_access.access_create = data.access_create
      site_contractor_access.access_show = data.access_show
      site_contractor_access.access_edit = data.access_edit
      site_contractor_access.access_delete = data.access_delete
      window.localStorage.setItem('site_contractor_access', JSON.stringify(site_contractor_access))
    },

    setup_modules_set(setup_modules)
    {
      this.contractor_module_set(setup_modules[0])
      this.developer_module_set(setup_modules[1])
      this.module_brand_module_set(setup_modules[2])
      this.project_category_module_set(setup_modules[3])
      this.project_engineer_group_module_set(setup_modules[4])
      this.project_type_module_set(setup_modules[5])
      this.safety_observation_category_module_set(setup_modules[6])
      this.user_module_set(setup_modules[7])
    },

    contractor_module_set(data)
    {
      const contractor_access = {}

      contractor_access.module_code = data.module_code
      contractor_access.access_listing = data.access_listing
      contractor_access.access_create = data.access_create
      contractor_access.access_show = data.access_show
      contractor_access.access_edit = data.access_edit
      contractor_access.access_delete = data.access_delete
      window.localStorage.setItem('contractor_access', JSON.stringify(contractor_access))
    },

    developer_module_set(data)
    {
      const developer_access = {}

      developer_access.module_code = data.module_code
      developer_access.access_listing = data.access_listing
      developer_access.access_create = data.access_create
      developer_access.access_show = data.access_show
      developer_access.access_edit = data.access_edit
      developer_access.access_delete = data.access_delete
      window.localStorage.setItem('developer_access', JSON.stringify(developer_access))
    },

    module_brand_module_set(data)
    {
      const module_brand_access = {}

      module_brand_access.module_code = data.module_code
      module_brand_access.access_listing = data.access_listing
      module_brand_access.access_create = data.access_create
      module_brand_access.access_show = data.access_show
      module_brand_access.access_edit = data.access_edit
      module_brand_access.access_delete = data.access_delete
      window.localStorage.setItem('module_brand_access', JSON.stringify(module_brand_access))
    },

    project_category_module_set(data)
    {
      const project_category_access = {}

      project_category_access.module_code = data.module_code
      project_category_access.access_listing = data.access_listing
      project_category_access.access_create = data.access_create
      project_category_access.access_show = data.access_show
      project_category_access.access_edit = data.access_edit
      project_category_access.access_delete = data.access_delete
      window.localStorage.setItem('project_category_access', JSON.stringify(project_category_access))
    },

    project_engineer_group_module_set(data)
    {
      const project_engineer_group_access = {}

      project_engineer_group_access.module_code = data.module_code
      project_engineer_group_access.access_listing = data.access_listing
      project_engineer_group_access.access_create = data.access_create
      project_engineer_group_access.access_show = data.access_show
      project_engineer_group_access.access_edit = data.access_edit
      project_engineer_group_access.access_delete = data.access_delete
      window.localStorage.setItem('project_engineer_group_access', JSON.stringify(project_engineer_group_access))
    },

    project_type_module_set(data)
    {
      const project_type_access = {}

      project_type_access.module_code = data.module_code
      project_type_access.access_listing = data.access_listing
      project_type_access.access_create = data.access_create
      project_type_access.access_show = data.access_show
      project_type_access.access_edit = data.access_edit
      project_type_access.access_delete = data.access_delete
      window.localStorage.setItem('project_type_access', JSON.stringify(project_type_access))
    },

    safety_observation_category_module_set(data)
    {
      const safety_observation_category_access = {}

      safety_observation_category_access.module_code = data.module_code
      safety_observation_category_access.access_listing = data.access_listing
      safety_observation_category_access.access_create = data.access_create
      safety_observation_category_access.access_show = data.access_show
      safety_observation_category_access.access_edit = data.access_edit
      safety_observation_category_access.access_delete = data.access_delete
      window.localStorage.setItem('safety_observation_category_access', JSON.stringify(safety_observation_category_access))
    },

    user_module_set(data)
    {
      const user_access = {}

      user_access.module_code = data.module_code
      user_access.access_listing = data.access_listing
      user_access.access_create = data.access_create
      user_access.access_show = data.access_show
      user_access.access_edit = data.access_edit
      user_access.access_delete = data.access_delete
      window.localStorage.setItem('user_access', JSON.stringify(user_access))
    },

    health_safety_modules_set(health_safety_modules)
    {
      this.safety_observation_module_set(health_safety_modules[0])
      this.safety_observation_report_module_set(health_safety_modules[1])
      this.toolbox_meeting_module_set(health_safety_modules[2])
    },

    safety_observation_module_set(data)
    {
      const safety_observation_access = {}

      safety_observation_access.module_code = data.module_code
      safety_observation_access.access_listing = data.access_listing
      safety_observation_access.access_create = data.access_create
      safety_observation_access.access_show = data.access_show
      safety_observation_access.access_edit = data.access_edit
      safety_observation_access.access_delete = data.access_delete
      window.localStorage.setItem('safety_observation_access', JSON.stringify(safety_observation_access))
    },

    safety_observation_report_module_set(data)
    {
      const safety_observation_report_access = {}

      safety_observation_report_access.module_code = data.module_code
      safety_observation_report_access.access_listing = data.access_listing
      safety_observation_report_access.access_create = data.access_create
      safety_observation_report_access.access_show = data.access_show
      safety_observation_report_access.access_edit = data.access_edit
      safety_observation_report_access.access_delete = data.access_delete
      window.localStorage.setItem('safety_observation_report_access', JSON.stringify(safety_observation_report_access))
    },
    toolbox_meeting_module_set(data)
    {
      const toolbox_meeting_access = {}

      toolbox_meeting_access.module_code = data.module_code
      toolbox_meeting_access.access_listing = data.access_listing
      toolbox_meeting_access.access_create = data.access_create
      toolbox_meeting_access.access_show = data.access_show
      toolbox_meeting_access.access_edit = data.access_edit
      toolbox_meeting_access.access_delete = data.access_delete
      window.localStorage.setItem('toolbox_meeting_access', JSON.stringify(toolbox_meeting_access))
    },
  },

}
